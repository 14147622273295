import React, { useState } from 'react'
import './styles/BurgerButton.scss'

const BurgerButton = props => {
  const [open, setOpen] = useState(false)
  
  return (
    <div className="burger-button" onClick={() => {
      setOpen(open === true ? false : true)
      document.getElementById('burger').classList.toggle("is-closed")
      document.body.classList.toggle("open");
    }
    }>
      <span id='burger' className={`burger burger-close`}></span>
    </div>
  )
}

export default BurgerButton
