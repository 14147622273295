import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

const BurgerSubMenu = ({ link, index, closeMenu, allMenus }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    setMenuOpen(false)
  }, [allMenus])

  return (
    <li className='nav-text' key={index} id={index}>
      <span onClick={() => setMenuOpen(!menuOpen)}>{link.name}<i className={`arrow ${menuOpen ? 'down' : 'right'}`}></i></span>
      <ul className={`dropdown dropdown-${menuOpen ? 'open-' + link.sub_options.length : 'closed'}`}>
        {link.sub_options.map((option, key) => {
          return <li className='nav-text' key={key}><Link title={option.name + ' | TGI Fridays'} onClick={() => closeMenu()} to={option.link}>{option.name}</Link></li>
        })}
      </ul>
    </li>
  )
}

export default BurgerSubMenu