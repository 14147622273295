import React from 'react'
import './styles/Socials.scss'

export const Socials = () => {
  return (
    <div className='socials'>
      <a title='TGI Fridays Instagram' href="https://www.instagram.com/tgifridaysau/?hl=en" target='_blank'>
        <div className='instagram' >
          <svg id="Instagram_Logo" data-name="Instagram Logo" xmlns="http://www.w3.org/2000/svg" width="32.983" height="32.983" viewBox="0 0 32.983 32.983">
            <path id="Path_342" data-name="Path 342" d="M1756.385,207.854c4.4,0,4.925.017,6.664.1a9.126,9.126,0,0,1,3.062.568,5.463,5.463,0,0,1,3.13,3.13,9.128,9.128,0,0,1,.568,3.062c.079,1.739.1,2.261.1,6.664s-.017,4.925-.1,6.664a9.128,9.128,0,0,1-.568,3.062,5.463,5.463,0,0,1-3.13,3.13,9.124,9.124,0,0,1-3.062.568c-1.739.079-2.26.1-6.664.1s-4.925-.017-6.664-.1a9.122,9.122,0,0,1-3.062-.568,5.461,5.461,0,0,1-3.13-3.13,9.128,9.128,0,0,1-.568-3.062c-.079-1.739-.1-2.261-.1-6.664s.017-4.925.1-6.664a9.127,9.127,0,0,1,.568-3.062,5.461,5.461,0,0,1,3.13-3.13,9.123,9.123,0,0,1,3.062-.568c1.739-.079,2.26-.1,6.664-.1m0-2.971c-4.479,0-5.04.019-6.8.1a12.105,12.105,0,0,0-4,.766,8.432,8.432,0,0,0-4.823,4.823,12.106,12.106,0,0,0-.766,4c-.08,1.759-.1,2.321-.1,6.8s.019,5.04.1,6.8a12.107,12.107,0,0,0,.766,4,8.432,8.432,0,0,0,4.823,4.823,12.1,12.1,0,0,0,4,.767c1.759.08,2.321.1,6.8.1s5.04-.019,6.8-.1a12.1,12.1,0,0,0,4-.767,8.434,8.434,0,0,0,4.823-4.823,12.1,12.1,0,0,0,.766-4c.08-1.759.1-2.321.1-6.8s-.019-5.04-.1-6.8a12.1,12.1,0,0,0-.766-4,8.434,8.434,0,0,0-4.823-4.823,12.106,12.106,0,0,0-4-.766c-1.759-.08-2.321-.1-6.8-.1Z" transform="translate(-1739.894 -204.883)" fill="#fff" />
            <path id="Path_343" data-name="Path 343" d="M1870.918,327.439a8.469,8.469,0,1,0,8.469,8.469A8.469,8.469,0,0,0,1870.918,327.439Zm0,13.966a5.5,5.5,0,1,1,5.5-5.5A5.5,5.5,0,0,1,1870.918,341.4Z" transform="translate(-1854.427 -319.416)" fill="#fff" />
            <circle id="Ellipse_8" data-name="Ellipse 8" cx="1.979" cy="1.979" r="1.979" transform="translate(22.496 7.688) rotate(-45)" fill="#fff" />
          </svg>
        </div>
      </a>
      <a title='TGI Fridays Facebook' href="https://www.facebook.com/TGIFRIDAYSAU" target='_blank'>
        <div className='facebook' >
          <svg id="Facebook_Logo" data-name="Facebook Logo" xmlns="http://www.w3.org/2000/svg" width="33.035" height="32.834" viewBox="0 0 33.035 32.834">
            <path id="Path_344" data-name="Path 344" d="M2658.785,204.485a16.519,16.519,0,0,0-2.581,32.834V225.777h-4.194V221h4.194v-3.639c0-4.14,2.466-6.426,6.239-6.426a25.4,25.4,0,0,1,3.7.323v4.065h-2.083a2.387,2.387,0,0,0-2.692,2.579V221h4.581l-.732,4.775h-3.849v11.542a16.519,16.519,0,0,0-2.581-32.834Z" transform="translate(-2642.268 -204.485)" fill="#fff" />
          </svg>
        </div>
      </a>
      {/* <a title='TGI Fridays Tiktok' href="https://www.tiktok.com/@tgifridaysau" target='_blank'>
        <div className='tiktok' >
          <svg xmlns="http://www.w3.org/2000/svg" width="29" height="32" fill='white' viewBox="0 0 512 512" id="icons"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" /></svg>
        </div>
      </a> */}
    </div>
  )
}
