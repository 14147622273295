import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

const index = ({children}) => {
  return (
    <div className={`app`}>
          <NavBar />
      <main className='body'>
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default index
