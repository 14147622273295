import React, { useState } from 'react'
import { Link } from "gatsby"
import './styles/BurgerMenu.scss'
import { Socials } from './Socials'
import AppStores from './AppStores'
import BurgerSubMenu from './BurgerSubMenu'

const BurgerMenu = props => {
  /**
   * make sub text same size as lower options
   */
  const [allMenus, setAllMenus] = useState(false)
  const navOptions = [
    {
      name: 'Menu',
      link: '',
      sub_options: [
        {
          name: 'Food',
          link: '/menu/food'
        },
        {
          name: 'Drinks',
          link: '/menu/drinks'
        },
        {
          name: 'Delivery',
          link: '/menu/delivery'
        },
        // {
        //   name: "What's New",
        //   link: '/whats-on/new-menu-items/'
        // },
        {
          name: 'RSPCA Approved',
          link: '/about/rspca'
        }
      ]
    },
    {
      name: 'Book A Table',
      link: '/book',
      sub_options: []
    },
    {
      name: 'Locations',
      link: '/locations',
      sub_options: []
    },
    {
      name: "What's On",
      link: '/whats-on',
      sub_options: [
        {
          name: 'Events',
          link: '/whats-on/'
        },
        {
          name: 'Sports',
          link: '/whats-on/sports'
        }
      ]
    },
    {
      name: 'Functions',
      link: '/functions',
      sub_options: []
    },
    {
      name: 'Order Now',
      link: '/order',
      sub_options: []
    },
    {
      name: 'MyFridays',
      link: '/rewards',
      sub_options: []
    },
    {
      name: 'About Us',
      link: '/about',
      sub_options: []
    },
    {
      name: 'Careers',
      link: '/careers',
      sub_options: []
    },
    {
      name: 'Franchising',
      link: '/franchise',
      sub_options: []
    },
    {
      name: 'Gift Cards',
      link: '/gift-cards',
      sub_options: []
    },
    {
      name: 'Contact Us',
      link: '/contact',
      sub_options: []
    }
  ]

  /**
   * Handles toggling classes of the body and burger elements to adjust styles when menu is open/closed
   */
  const closeMenu = () => {
    document.body.classList.toggle("open")
    document.getElementById('burger').classList.toggle('is-closed')
    setAllMenus(!allMenus)
  }

  /**
   * Creates the nav list item as either a normal link or dropdown links
   * @param {*} link 
   * @param {*} index 
   * @returns A Link element or dropdown element
   */
  const createNav = (link, index) => {
    if (link.sub_options.length !== 0) {
      return (
        <BurgerSubMenu allMenus={allMenus} link={link} index={index} closeMenu={() => closeMenu()} />
      )
    } else {
      return <li className='nav-text' key={index}><Link title={link.name + ' | TGI Fridays'} onClick={() => closeMenu()} to={link.link}>{link.name}</Link></li>
    }
  }


  return (
    <div className="burger-menu">
      <div className='burger-top' />
      <div className='burger-content'>
        <ul>
          {navOptions.map((link, index) => createNav(link, index))}
        </ul>
        <Socials />
        <AppStores />
      </div>

    </div>
  )
}

export default BurgerMenu
