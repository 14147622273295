exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-franchise-faqs-jsx": () => import("./../../../src/pages/franchise/faqs.jsx" /* webpackChunkName: "component---src-pages-franchise-faqs-jsx" */),
  "component---src-pages-test-index-js": () => import("./../../../src/pages/test-index.js" /* webpackChunkName: "component---src-pages-test-index-js" */),
  "component---src-templates-book-jsx": () => import("./../../../src/templates/Book.jsx" /* webpackChunkName: "component---src-templates-book-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../../../src/templates/Faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-gift-cards-jsx": () => import("./../../../src/templates/GiftCards.jsx" /* webpackChunkName: "component---src-templates-gift-cards-jsx" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-locations-location-jsx": () => import("./../../../src/templates/Locations/Location.jsx" /* webpackChunkName: "component---src-templates-locations-location-jsx" */),
  "component---src-templates-locations-locations-jsx": () => import("./../../../src/templates/Locations/Locations.jsx" /* webpackChunkName: "component---src-templates-locations-locations-jsx" */),
  "component---src-templates-menu-menu-home-jsx": () => import("./../../../src/templates/Menu/MenuHome.jsx" /* webpackChunkName: "component---src-templates-menu-menu-home-jsx" */),
  "component---src-templates-menu-menu-jsx": () => import("./../../../src/templates/Menu/Menu.jsx" /* webpackChunkName: "component---src-templates-menu-menu-jsx" */),
  "component---src-templates-order-jsx": () => import("./../../../src/templates/Order.jsx" /* webpackChunkName: "component---src-templates-order-jsx" */),
  "component---src-templates-rewards-jsx": () => import("./../../../src/templates/Rewards.jsx" /* webpackChunkName: "component---src-templates-rewards-jsx" */),
  "component---src-templates-template-jsx": () => import("./../../../src/templates/template.jsx" /* webpackChunkName: "component---src-templates-template-jsx" */),
  "component---src-templates-terms-jsx": () => import("./../../../src/templates/Terms.jsx" /* webpackChunkName: "component---src-templates-terms-jsx" */),
  "component---src-templates-vacancies-jsx": () => import("./../../../src/templates/Vacancies.jsx" /* webpackChunkName: "component---src-templates-vacancies-jsx" */),
  "component---src-templates-whats-on-jsx": () => import("./../../../src/templates/WhatsOn.jsx" /* webpackChunkName: "component---src-templates-whats-on-jsx" */)
}

